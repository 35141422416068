<template>
  <div>
    <v-container fluid>
      <done-forms-enrollment-counters-index></done-forms-enrollment-counters-index>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'DoneFormsEnrollmentCounters',
    components: {
      DoneFormsEnrollmentCountersIndex: () => import ('@/components/done_forms_enrollment_counters/DoneFormsEnrollmentCountersIndex.vue')
    },
  }
</script>